import React from "react";
import { FaBed, FaHotel } from "react-icons/fa";
import "./Accomodation.css";

function Accomodation() {
  return (
    <div className="accomodation">
      <div className="heading1">Accomodation</div>
      <div className="guidelines">
        <div className="g_bold g_title">
          Hostel accommodation for students (UG/PG/PhD) and Postdocs
        </div>
        <ol className="g_list">
          <li>
            Accommodation related information will be shared only to those
            participants who have registered for CompFlu-2024.
          </li>
          <li>
            Hostel charges will approximately be Rs 550 (inclusive of GST) per
            day per participant. Hostel rooms will be available for CompFlu-2024
            from 15 to 19 December 2024. Participants registered for the
            Pre-Conference Workshop can avail rooms from 13 December onwards.
          </li>
          <li>
            After 15 October 2024, Hostel Office IITH will share a google form
            with payment link to those who have expressed interest to avail
            hostel accommodation on the registration form.
          </li>
          <li>
            All participants are required to pay in advance to confirm the
            hostel accommodation. After payment, Hostel Office IITH will
            communicate the details of the room allocation.
          </li>
          <li>
            Participants staying at the hostel can avail the dining facilities
            at IITH on pay-per-meal basis.
          </li>
          <li>
            <span className="g_bold">
              {" "}
              REFUND OR CHANGES TO ROOM ALLOTMENT WILL NOT BE ENTERTAINED.{" "}
            </span>
          </li>
        </ol>
        <div className="g_bold g_title">
          Guest house accommodation for faculty/scientist/industry personnel
        </div>
        <ol className="g_list">
          <li>
            All registered participants who have expressed interest to avail
            guest house accommodation in the registration form will allotted a
            room on arrival.
          </li>
          <li>
            Rooms have been reserved from 15 to 19 December 2024. Registered
            participants can avail of this accommodation upon payment at the
            guest house reception in-person. Please present your Identity card,
            Passport or Temporary Residence Card during check-in. Current guest
            house tariff is as follows:
            <br />
            <br />
            <br />
            <div className="table_info">
              {
                <table>
                  <tr>
                    <th>Room Type</th>
                    <th>Occupancy Type</th>
                    <th>Tariff per day (INR)</th>
                  </tr>
                  <tr>
                    <td rowspan="2">Standard Room (Room with 2 single beds)</td>
                    <td>Single</td>
                    <td>1500 + 12% GST</td>
                  </tr>
                  <tr>
                    <td>Double</td>
                    <td>2000 + 12% GST</td>
                  </tr>
                  <tr>
                    <td>Accessible Room (for PWD)</td>
                    <td>Single/Double</td>
                    <td>1500 + 12% GST</td>
                  </tr>
                  <tr>
                    <td rowspan="3">
                      Executive room (Room with King Size bed & Single bed){" "}
                    </td>
                    <td>Single</td>
                    <td>2000 + 12% GST</td>
                  </tr>
                  <tr>
                    <td>Double</td>
                    <td>2300 + 12% GST</td>
                  </tr>
                  <tr>
                    <td>Triple</td>
                    <td>2600 + 12% GST</td>
                  </tr>
                  <tr>
                    <td>Suite</td>
                    <td>Single/Double</td>
                    <td>2800 + 12% GST</td>
                  </tr>
                  <tr>
                    <td>Extra bed</td>
                    <td colspan="2">400 + 12% GST</td>
                  </tr>
                </table>
              }
            </div>
          </li>
          <li>
            Guest house follows a 24h check-in policy. The charges are inclusive
            of a complimentary breakfast for a 24h stay.
          </li>
          <li>
            Lunch and dinner can be arranged at the guesthouse on the request of
            the guest. However, the charges for the same will have to be paid by
            the guest in addition to the room rent. The order for lunch or
            dinner should be placed at least an hour in advance of the
            lunch/dinner time.
          </li>
        </ol>
      </div>

      <br />
      <br />
      <div className="acc_para">
        {/*Accommodation will be made available on a first-come, first-served basis at nominal charges for participants as follows:
        <br />
        <br />
        <ul className="g_list_1">
          <li>Faculty/Scientist participants: at the IIT Hyderabad guest house</li>
          <li>Student participants: at the IIT Hyderabad hostels</li>
        </ul>
        <br />
        <br />
        Further details about accommodation and payment will be provided on this page after 15th October.*/}
        <div className="acc_para_head">Accomodation Off Campus</div>
        If participants wish to stay off-campus, suitable suggestions for hotels
        near IIT Hyderabad and in the city are provided below.
      </div>
      <div className="Venue">
        <div className="Card">
          <div className="card">
            <div className="card-body">
              <div className="upper-body">
                <FaBed style={{ color: "#000099" }} />
                <br />
                <span>
                  <p className="heading">Stay nearby</p>
                </span>
                <small className="text-muted">within 15 Kms</small>
                <br />
                <br />
              </div>
              <blockquote>
                <ul>
                  <li className="list list-title1">
                    At Sangareddy
                    <ul className="sublist">
                      <li>
                        <p style={{ margin: "5px auto", color: "grey" }}>
                          <a
                            style={{ color: "black" }}
                            className="link"
                            href="https://www.google.com/travel/hotels/Sangareddy%2C%20Telangana/entity/CgsIyvODweyql5OXARAB?q=hotels%20in%20sangareddy&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518327%2C4597339%2C4718358%2C4723331%2C4731329%2C4757164%2C4801235%2C4814050%2C4816977%2C4821091%2C4852066%2C4861687%2C4864715%2C4871746%2C4873776%2C4877114%2C4878644%2C4879519%2C4881565%2C4881986&hl=en-IN&gl=in&cs=1&ssta=1&ts=CAEaIAoCGgASGhIUCgcI6A8QAhgPEgcI6A8QAhgQGAEyAhAAKgkKBToDSU5SGgA&rp=EMrzg8HsqpeTlwEQiMr2iP3oyrnvARCQ2diNkLT479wBEOTQ7piAg_r1wQE4AUAASAKiARVTYW5nYXJlZGR5LCBUZWxhbmdhbmHAAQOaAgIIAA&ap=aAE&ictx=1&ved=0CAAQ5JsGahcKEwjQ7OWho6aEAxUAAAAAHQAAAAAQAw&utm_campaign=sharing&utm_medium=link&utm_source=htls"
                            target="blank"
                          >
                            Redla's Inn
                          </a>{" "}
                          10kms
                        </p>
                      </li>
                      <li>
                        <p style={{ margin: "5px auto", color: "grey" }}>
                          <a
                            style={{ color: "black" }}
                            className="link"
                            href="https://trishulbanquethallluxuryrooms.business.site/"
                            target="blank"
                          >
                            Trishul Luxury Hotel Rooms
                          </a>{" "}
                          8kms
                        </p>
                      </li>
                      <li>
                        <p style={{ margin: "5px auto", color: "grey" }}>
                          <a
                            style={{ color: "black" }}
                            className="link"
                            href="https://www.google.com/travel/hotels/Sangareddy%2C%20Telangana/entity/CgsI5NDumICD-vXBARAB?q=hotels%20in%20sangareddy&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518327%2C4597339%2C4718358%2C4723331%2C4731329%2C4757164%2C4801235%2C4814050%2C4816977%2C4821091%2C4852066%2C4861687%2C4864715%2C4871746%2C4873776%2C4877114%2C4878644%2C4879519%2C4881565%2C4881986&hl=en-IN&gl=in&cs=1&ssta=1&ts=CAEaIAoCGgASGhIUCgcI6A8QAhgPEgcI6A8QAhgQGAEyAhAAKgkKBToDSU5SGgA&rp=EMrzg8HsqpeTlwEQiMr2iP3oyrnvARCQ2diNkLT479wBEOTQ7piAg_r1wQE4AUAASAKiARVTYW5nYXJlZGR5LCBUZWxhbmdhbmHAAQOaAgIIAA&ap=aAE&ictx=1&ved=0CAAQ5JsGahcKEwjwsvDDo6aEAxUAAAAAHQAAAAAQBA&utm_campaign=sharing&utm_medium=link_btn&utm_source=htls"
                            target="blank"
                          >
                            Renuka's Inn
                          </a>{" "}
                          8kms
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className="list list-title1">
                    At Isnapur
                    <ul className="sublist">
                      <li>
                        <p style={{ margin: "5px auto", color: "grey" }}>
                          <a
                            style={{ color: "black" }}
                            className="link"
                            href="https://www.google.com/travel/hotels/Isnapur%2C%20Hyderabad%2C%20Telangana/entity/CgsItO3rnaikkoLRARAB?q=hotels%20in%20isnapur&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518327%2C4597339%2C4718358%2C4723331%2C4731329%2C4757164%2C4801235%2C4814050%2C4816977%2C4821091%2C4852066%2C4861687%2C4864715%2C4871746%2C4873776%2C4877114%2C4878644%2C4879519%2C4881565%2C4881986&hl=en-IN&gl=in&cs=1&ssta=1&ts=CAEaIAoCGgASGhIUCgcI6A8QAhgPEgcI6A8QAhgQGAEyAhAAKgkKBToDSU5SGgA&rp=ELTt652opJKC0QEQpJnfzZ3bkpebARCwz_S9lui1-oIBENap45fQxYCqcTgBQABIAqIBHUlzbmFwdXIsIEh5ZGVyYWJhZCwgVGVsYW5nYW5hwAEDmgICCAA&ap=aAE&ictx=1&ved=0CAAQ5JsGahcKEwjI7OLupKaEAxUAAAAAHQAAAAAQBA&utm_campaign=sharing&utm_medium=link_btn&utm_source=htls"
                            target="blank"
                          >
                            Pankaj Palace
                          </a>{" "}
                          13kms
                        </p>
                      </li>
                      <li>
                        <p style={{ margin: "5px auto", color: "grey" }}>
                          <a
                            style={{ color: "black" }}
                            className="link"
                            href="https://noblelodgeorr.weebly.com/"
                            target="blank"
                          >
                            Nobel Lodge
                          </a>{" "}
                          15kms
                        </p>
                      </li>
                      <li>
                        <p style={{ margin: "5px auto", color: "grey" }}>
                          <a
                            style={{ color: "black" }}
                            className="link"
                            href="https://www.google.co.in/travel/hotels/entity/CgoIzeer66DG1pEdEAE?utm_campaign=sharing&utm_medium=link&utm_source=htls&ved=0CAAQ5JsGahcKEwjY2JfP_aqEAxUAAAAAHQAAAAAQAw&ts=CAEaBAoCGgAqBAoAGgA"
                            target="blank"
                          >
                            Square Inn Lodge
                          </a>{" "}
                          13kms
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className="list list-title1">
                    At Shakarpally
                    <ul className="sublist">
                      <li>
                        <p style={{ margin: "5px auto", color: "grey" }}>
                          <a
                            style={{ color: "black" }}
                            className="link"
                            href="https://www.palmexotica.com/"
                            target="blank"
                          >
                            Palm Exotica
                          </a>{" "}
                          25kms
                        </p>
                      </li>
                      <li>
                        <p style={{ margin: "5px auto", color: "grey" }}>
                          <a
                            style={{ color: "black" }}
                            className="link"
                            href="https://www.google.com/travel/hotels/Shankarpalle%2C%20Telangana/entity/CgoIxpmVqqK28cVIEAE?q=hotels%20in%20shankarpally&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4518327%2C4597339%2C4718358%2C4723331%2C4731329%2C4757164%2C4801235%2C4814050%2C4816977%2C4821091%2C4852066%2C4861687%2C4864715%2C4871746%2C4873776%2C4877114%2C4878644%2C4879519%2C4881565%2C4881986&hl=en-IN&gl=in&cs=1&ssta=1&ts=CAEaIAoCGgASGhIUCgcI6A8QAhgPEgcI6A8QAhgQGAEyAhAAKgkKBToDSU5SGgA&rp=EMuz47rfkJ_PehD66O2L7ZW91xYQuIGewK3RiaZ2EMaZlaqitvHFSDgBQABIAqIBF1NoYW5rYXJwYWxsZSwgVGVsYW5nYW5hwAEDmgICCAA&ap=aAE&ictx=1&ved=0CAAQ5JsGahcKEwiQ6PWlp6aEAxUAAAAAHQAAAAAQBA&utm_campaign=sharing&utm_medium=link_btn&utm_source=htls"
                            target="blank"
                          >
                            Pragati Resorts
                          </a>{" "}
                          32kms
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </blockquote>
            </div>
          </div>
        </div>
        <div className="Card">
          <div className="card">
            <div className="card-body">
              <div className="upper-body">
                <FaHotel style={{ color: "#000099" }} />
                <br />
                <span>
                  <p className="heading">Stay at City</p>
                </span>
                <small className="text-muted" style={{ marginTop: 0 }}>
                  within 50 Kms
                </small>
                <br />
                <br />
              </div>
              <blockquote>
                <ul className="list" style={{ fontSize: "1.15rem" }}>
                  <li>
                    <p style={{ color: "grey" }}>
                      <a
                        style={{ color: "black" }}
                        className="link"
                        href="https://www.novotelhyderabad.com/meetings-events/facilities-international-convention-centre-hicc/"
                        target="blank"
                      >
                        Novotel
                      </a>{" "}
                      at HICC 36kms
                    </p>
                  </li>
                  <br />
                  <li>
                    <p style={{ color: "grey" }}>
                      <a
                        style={{ color: "black" }}
                        className="link"
                        href="https://www.itchotels.com/in/en/itckohenur-hyderabad?utm_source=google&utm_medium=cpc&utm_campaign=HQ-DTL|HTL|Hyderabad|ITCKohenur|Search|Conversions|PANIndia|always-on|Owned|LC|GGL|Exact|NA|Business|HYDLK|Brand&utm_content=search&gad_source=1&gclid=Cj0KCQiAy9msBhD0ARIsANbk0A-CjQfhXPIh2-6zZ9jXyFse0My27s5waKhEWH6LRHXMEpaY62kLlAoaAsPsEALw_wcB"
                        target="blank"
                      >
                        ITC Kohenur
                      </a>
                      , Hitec City 46kms
                    </p>
                  </li>
                  <br />
                  <li>
                    <p style={{ color: "grey" }}>
                      <a
                        style={{ color: "black" }}
                        className="link"
                        href="https://www.tajhotels.com/en-in/taj/taj-krishna-hyderabad/?from=06/01/2024&to=07/01/2024&rooms=1&adults=1&children=0"
                        target="blank"
                      >
                        Taj Krishna
                      </a>
                      , Banajara Hills 45kms
                    </p>
                  </li>
                  <br />
                  <li>
                    <p style={{ color: "grey" }}>
                      <a
                        style={{ color: "black" }}
                        className="link"
                        href="https://www.google.com/travel/search?q=minerva%20grand%20kondapur&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4284970%2C4291517%2C4597339%2C4814050%2C4874190%2C4893075%2C4924070%2C4965990%2C10207532%2C10208620%2C72248047%2C72248049%2C72277293%2C72298667%2C72302247%2C72313836%2C72317059%2C72406588%2C72412680%2C72414906%2C72421566%2C72424732%2C72430152%2C72430562%2C72440517%2C72442338%2C72448521%2C72458707&hl=en-IN&gl=in&cs=1&ssta=1&ts=CAEaSQopEicyJTB4M2JjYjkzY2ZiYmQ0MzZmMToweDYwZWFhZWRiZTA3MjdmMzQSHBIUCgcI6A8QARgGEgcI6A8QARgHGAEyBAgAEAAqBwoFOgNJTlI&qs=CAEyJkNoZ0l0UDdKZzc3YnFfVmdHZ3d2Wnk4eGFHYzBYM2g1ZERVUUFROAJCCQk0f3Lg267qYEIJCTR_cuDbrupg&ap=ugEIb3ZlcnZpZXc&ictx=1&sa=X&utm_campaign=sharing&utm_medium=link_btn&utm_source=htls"
                        target="blank"
                      >
                        Minerva Grand
                      </a>
                      , Kondapur 46kms
                    </p>
                  </li>
                  <br />
                  <li>
                    <p style={{ color: "grey" }}>
                      <a
                        style={{ color: "black" }}
                        className="link"
                        href="https://www.lemontreehotels.com/lemon-tree-hotel/hyderabad/gachibowli-hyderabad"
                        target="blank"
                      >
                        Lemon Tree Hotels
                      </a>
                      , Gachibowli 45kms
                    </p>
                  </li>
                  <br />
                  <li>
                    <p style={{ color: "grey" }}>
                      <a
                        style={{ color: "black" }}
                        className="link"
                        href="https://www.radissonhotels.com/en-us/hotels/radisson-hyderabad-hitec-city"
                        target="blank"
                      >
                        Radison Hotels
                      </a>
                      , Gachibowli 45kms
                    </p>
                  </li>
                  <br />
                </ul>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", margin: "0 15% 75px", color: "grey" }}>
        <p>
          <b style={{ color: "black" }}>NOTE:</b> This information is suggestive
          only, and mentioning a hotel does not imply endorsement or
          recommendation of any kind. This list is useful in case you are unable
          to get accommodation in an IITH guest house or hostel. This is not an
          exhaustive list. There may be other lodges or hotels that are not on
          this list.
        </p>
      </div>
    </div>
  );
}

export default Accomodation;
